<template>
  <v-overlay v-if="isVisible" opacity="0.4" fixed :value="true">
    <v-progress-circular
      indeterminate
      size="100"
      color="primary"
    ></v-progress-circular>
    <v-container></v-container>
  </v-overlay>
</template>

<script>
export default {
  name: "Loader",
  props: {
    isVisible: { type: Boolean, required: true },
    text: { type: String, required: false, default: "Please wait ..." },
  },
};
</script>

<style lang="scss"></style>
